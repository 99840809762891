import { createContext, useContext, useMemo, useReducer } from 'react';
import { selectConfig } from '../components/PlansTable/content';
import { SalesPortalReducerAction, SalesPortalState } from '../types';

const SalesPortalContext = createContext<
    | {
          state: SalesPortalState;
          dispatch: React.Dispatch<SalesPortalReducerAction>;
      }
    | undefined
>(undefined);

const generateDefaultState = (): SalesPortalState => {
    return {
        plan: {
            pricebook_id: selectConfig.insurance.pricebook_id,
            package_name: '',
            monthly_payment_amount: 0,
            package_id: 14,
            type: 'maintenance',
            customer_type: 'homeowner',
            contribution: 0,
            billing: 'monthly',
            products: [],
            source: '',
        },
        contact: {
            firstName: '',
            lastName: '',
            phonePrefix: '44',
            phone: '',
            email: '',
            marketing_email: false,
            marketing_phone: false,
            marketing_sms: false,
        },
        property: {
            searched: '',
            street: '',
            town: '',
            county: '',
            postcode: '',
            country: 'United Kingdom',
        },
        payment: {
            method: 'directDebit',
            startDate: new Date(),
            termsAndConditionsApproved: false,
        },
        formSectionValid: {
            contact: false,
            property: false,
            payment: false,
        },
        request: {
            isRequesting: false,
        },
    };
};

export const SalesPortalReducer = (state: SalesPortalState, action: SalesPortalReducerAction) => {
    switch (action.type) {
        case 'setPlan': {
            return {
                ...state,
                plan: {
                    ...state.plan,
                    ...action.data,
                    packageDetails: action.data.packageDetails ?? state.plan.packageDetails,
                },
            };
        }
        case 'setContact': {
            return {
                ...state,
                contact: {
                    ...state.contact,
                    ...action.data,
                },
            };
        }
        case 'setProperty': {
            return {
                ...state,
                property: {
                    ...state.property,
                    ...action.data,
                },
            };
        }
        case 'setPayment': {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    ...action.data,
                },
            };
        }
        case 'setRequest': {
            return {
                ...state,
                request: {
                    ...state.request,
                    ...action.data,
                },
            };
        }
        case 'setFormSectionValid': {
            return {
                ...state,
                formSectionValid: {
                    ...state.formSectionValid,
                    ...action.data,
                },
            };
        }
        default: {
            throw new Error(`Unkown action type: ${action.type}`);
        }
    }
};

interface SalesPortalProviderProps {
    children: JSX.Element | JSX.Element[];
    defaultInitialState?: SalesPortalState;
}
export function SalesPortalProvider({
    children,
    defaultInitialState = generateDefaultState(),
}: SalesPortalProviderProps): JSX.Element {
    const [state, dispatch] = useReducer(SalesPortalReducer, defaultInitialState);

    const memoizedValue = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state]
    );

    return (
        <SalesPortalContext.Provider value={memoizedValue}>{children}</SalesPortalContext.Provider>
    );
}

export const useSalesPortalContext = (): {
    state: SalesPortalState;
    dispatch: React.Dispatch<SalesPortalReducerAction>;
} => {
    const context = useContext(SalesPortalContext);

    if (context === undefined)
        throw new Error('useSalesPortalContext must be used within a provider');

    return context;
};
