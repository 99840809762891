export const packageIds = {
    'your-boiler': [1, 4, 10, 13, 16, 19, 22],
    'your-heating': [2, 5, 11, 14, 17, 20, 24],
    'your-home': [3, 6, 12, 15, 18, 21, 23],
    'your-home-plus': [29, 30],
    'smart-cover': [31, 36, 37, 38, 39, 40, 41, 43, 44, 45, 46, 47, 48, 49, 50],
    appliances: [49, 50],
};

export const isSmartCoverPackage = (packageId: number) =>
    packageIds['smart-cover'].includes(packageId) || packageIds.appliances.includes(packageId);
