import { Button } from '@HometreeEngineering/component-library';
import { BillingRequest, BillingRequestFlow } from '@gocardless/react-dropin';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { registerCustomer } from '../api/registerCustomer';
import { SalesPortalReducerAction, SalesPortalState } from '../types';
import { errorToastOptions } from './toastOptions';

export const handleFormSubmission = async (
    latestState: SalesPortalState,
    thirdPartyValues: {
        goCardless?: SalesPortalState['payment']['goCardless'];
    },
    dispatch: React.Dispatch<SalesPortalReducerAction>,
    navigate: ReturnType<typeof useNavigate>
) => {
    // Show loading state
    dispatch({
        type: 'setRequest',
        data: {
            isRequesting: true,
        },
    });

    try {
        // Register customer request
        const response = await registerCustomer({
            ...latestState,
            payment: {
                ...latestState.payment,
                ...thirdPartyValues,
            },
        });

        if (typeof response.contractId !== 'undefined') {
            navigate(
                `/sales-portal/success?contract-id=${response.contractId}&property-id=${response.propertyId}`
            );
        } else if (response.errorCode === 'PropertyHasLiveOrFutureContract') {
            toast(
                (t) => (
                    <div>
                        <p>
                            A contract for this property already exists. Please enquiry with the
                            customer.
                        </p>
                        <Button type="button" onClick={() => toast.dismiss(t.id)}>
                            Dismiss
                        </Button>
                    </div>
                ),
                errorToastOptions
            );
        } else if (response.errorCode === 'EmailAlreadyExists') {
            toast(
                (t) => (
                    <div>
                        <p>
                            This account already exists but details don&apos;t match. Please enquiry
                            with the customer or report the issue to our support team.
                        </p>
                        <Button type="button" onClick={() => toast.dismiss(t.id)}>
                            Dismiss
                        </Button>
                    </div>
                ),
                errorToastOptions
            );
        } else {
            toast(
                (t) => (
                    <div>
                        <p>
                            There was an error whilst registering the customer, please report the
                            issue to our support team.
                        </p>
                        <Button type="button" onClick={() => toast.dismiss(t.id)}>
                            Dismiss
                        </Button>
                    </div>
                ),
                errorToastOptions
            );
        }
    } catch (error: unknown) {
        toast(
            (t) => (
                <div>
                    <p>
                        There was an error whilst registering the customer, please report the issue
                        to our support team.
                    </p>
                    <Button type="button" onClick={() => toast.dismiss(t.id)}>
                        Dismiss
                    </Button>
                </div>
            ),
            errorToastOptions
        );
    } finally {
        dispatch({
            type: 'setRequest',
            data: {
                isRequesting: false,
            },
        });
    }
};

export const handleGoCardlessSuccess =
    (
        latestState: SalesPortalState,
        dispatch: React.Dispatch<SalesPortalReducerAction>,
        navigate: ReturnType<typeof useNavigate>
    ) =>
    async (billingRequest: BillingRequest, billingRequestFlow: BillingRequestFlow) => {
        const goCardlessIds = {
            billing_request_id: billingRequest.id || '',
            billing_request_flow_id: billingRequestFlow.id || '',
            creditor_id: billingRequest.links?.creditor || '',
            customer_id: billingRequest.links?.customer || '',
            customer_bank_account_id: billingRequest.links?.customer_bank_account || '',
            customer_billing_detail_id: billingRequest.links?.customer_billing_detail || '',
            mandate_request_id: billingRequest.links?.mandate_request || '',
            mandate_request_mandate_id: billingRequest.links?.mandate_request_mandate || '',
        };

        dispatch({
            type: 'setPayment',
            data: {
                goCardless: goCardlessIds,
            },
        });

        await handleFormSubmission(
            latestState,
            {
                goCardless: goCardlessIds,
            },
            dispatch,
            navigate
        );
    };
