import { Button, TextInput } from '@HometreeEngineering/component-library';
import { validatePropertyContract } from 'features/salesPortal/api/validatePropertyContract';
import { useSalesPortalContext } from 'features/salesPortal/contexts/SalesPortalContext';
import { SalesPortalState } from 'features/salesPortal/types';
import { ALPHA_NUM_EXTENDED, POSTCODE_REGEX } from 'features/salesPortal/utils/validationRegex';
import { useEffect, useState } from 'react';
import PostcodeFinder, { Address } from '../PostcodeFinder';
import styles from './index.module.scss';

interface PropertyInformationFormProps {
    onSubmit: () => void;
}

const inputRegex: Record<keyof SalesPortalState['property'], RegExp | null> = {
    street: new RegExp(ALPHA_NUM_EXTENDED),
    town: new RegExp(ALPHA_NUM_EXTENDED),
    county: null,
    postcode: new RegExp(POSTCODE_REGEX),
    searched: null,
    country: null,
};

const isInputValid = (field: keyof typeof inputRegex, value?: string | boolean) => {
    if (inputRegex[field] === null) return true;
    if (!value) return false;
    if (!inputRegex[field]!.test(value as string)) return false;
    return true;
};

export const PropertyInformationForm = ({ onSubmit }: PropertyInformationFormProps) => {
    const {
        state: { property: propertyState, formSectionValid },
        dispatch,
    } = useSalesPortalContext();

    const [formErrors, setFormErrors] = useState({
        street: '',
        town: '',
        county: '',
        postcode: '',
        contractExists: '',
    });

    const handleOnAddressSelect = (addressObj: Address) => {
        dispatch({
            type: 'setProperty',
            data: {
                street: [addressObj.line1, addressObj.line2, addressObj.line3, addressObj.line4]
                    .map((str) => str.trim())
                    .filter((str) => !!str)
                    .join(', '),
                town: addressObj.town,
                county: addressObj.county,
            },
        });

        setFormErrors({
            street: '',
            town: '',
            county: '',
            postcode: '',
            contractExists: '',
        });
    };

    const handleOnSearch = () => {
        dispatch({
            type: 'setProperty',
            data: {
                street: '',
                town: '',
                county: '',
            },
        });
    };

    const handleInputBlur = (field: keyof typeof inputRegex) => () => {
        const errorMessageToField = {
            street: 'Please enter a valid street',
            town: 'Please enter a valid town',
            county: 'Please enter a valid county',
            postcode: 'Please enter a valid postcode',
        };

        if (typeof errorMessageToField[field as keyof typeof errorMessageToField] === 'undefined') {
            return;
        }

        if ((propertyState[field] || '') === '') {
            setFormErrors((prevState) => ({ ...prevState, [field]: 'This field is required' }));
        }

        if (!isInputValid(field, propertyState[field])) {
            setFormErrors((prevState) => ({
                ...prevState,
                [field]: errorMessageToField[field as keyof typeof errorMessageToField],
            }));
        } else {
            setFormErrors((prevState) => ({ ...prevState, [field]: '' }));
        }
    };

    useEffect(() => {
        let isValid = false;
        if (
            isInputValid('street', propertyState.street) &&
            isInputValid('town', propertyState.town) &&
            isInputValid('postcode', propertyState.postcode) &&
            formErrors.contractExists === ''
        ) {
            isValid = true;
        }

        if (formSectionValid.property !== isValid) {
            dispatch({
                type: 'setFormSectionValid',
                data: {
                    property: isValid,
                },
            });
        }
    }, [
        propertyState.street,
        propertyState.town,
        propertyState.county,
        propertyState.postcode,
        formErrors.contractExists,
        dispatch,
        formSectionValid,
    ]);

    const handleOnChange =
        (key: keyof SalesPortalState['property']) => (value: string | boolean) => {
            if (typeof inputRegex[key] !== 'undefined' && isInputValid(key, value)) {
                setFormErrors((prevState) => ({ ...prevState, contractExists: '', [key]: '' }));
            } else {
                setFormErrors((prevState) => ({ ...prevState, contractExists: '' }));
            }

            dispatch({
                type: 'setProperty',
                data: {
                    [key]: value,
                },
            });
        };

    const onNextClick = async (e?: React.MouseEvent<HTMLButtonElement> | undefined) => {
        if (formSectionValid.property) {
            const button = e!.currentTarget;
            button.disabled = true;
            button.innerText = 'Validating...';

            try {
                const contractExist = await validatePropertyContract(
                    propertyState.postcode,
                    propertyState.street
                );

                if (contractExist) {
                    setFormErrors((prevState) => ({
                        ...prevState,
                        contractExists:
                            'A contract for this property already exists and therefore cannot be registered.',
                    }));

                    return;
                }

                setFormErrors((prevState) => ({
                    ...prevState,
                    contractExists: '',
                }));
            } catch (error) {
                setFormErrors((prevState) => ({
                    ...prevState,
                    contractExists:
                        'An error occured when we attempted to validate the property, please contact support for assistance.',
                }));
            } finally {
                button.disabled = false;
                button.innerText = 'Next';
            }

            onSubmit();
        }
    };

    return (
        <div className={styles.propertyInformationForm} data-testid="property-information-form">
            <form>
                <PostcodeFinder
                    onSelect={handleOnAddressSelect}
                    onSearch={handleOnSearch}
                    postcode={propertyState.postcode}
                    setPostcode={handleOnChange('postcode')}
                />
            </form>
            <form>
                <TextInput
                    label="Street"
                    id="Street"
                    value={propertyState.street}
                    placeholder="Street"
                    className={styles.textInput}
                    onChange={handleOnChange('street')}
                    onBlur={handleInputBlur('street')}
                    error={formErrors.street}
                />
                <TextInput
                    label="Town"
                    id="Town"
                    value={propertyState.town}
                    placeholder="Town"
                    className={styles.textInput}
                    onChange={handleOnChange('town')}
                    onBlur={handleInputBlur('town')}
                    error={formErrors.town}
                />
                <TextInput
                    label="County"
                    id="County"
                    value={propertyState.county}
                    placeholder="County"
                    className={styles.textInput}
                    onChange={handleOnChange('county')}
                    onBlur={handleInputBlur('county')}
                    error={formErrors.county}
                />
                <TextInput
                    label="Postcode"
                    id="Postcode"
                    value={propertyState.postcode}
                    placeholder="Postcode"
                    className={styles.textInput}
                    onChange={handleOnChange('postcode')}
                    onBlur={handleInputBlur('postcode')}
                    error={formErrors.postcode}
                />
            </form>

            {formErrors.contractExists && (
                <p className={styles.error} data-testid="errorMessage">
                    {formErrors.contractExists}
                </p>
            )}

            <Button
                onClick={onNextClick}
                isDisabled={!formSectionValid.property}
                customStyle={styles.nextBtn}
            >
                Next
            </Button>
        </div>
    );
};
