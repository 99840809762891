import { Checkbox, DatePicker, Modal } from '@HometreeEngineering/component-library';
import { addMonths } from 'date-fns';
import { useSalesPortalContext } from 'features/salesPortal/contexts/SalesPortalContext';
import { SalesPortalState } from 'features/salesPortal/types';
import { useEffect, useState } from 'react';
import { ReactComponent as DirectDebit } from '../../assets/logos/directDebit.svg';
import { ReactComponent as MasterCard } from '../../assets/logos/mastercard.svg';
import { ReactComponent as Visa } from '../../assets/logos/visa.svg';
import { PromoReferralCode } from '../PromoReferralCode';
import Radio from '../Radio';
import styles from './index.module.scss';

export const PaymentInformationForm = () => {
    const { state, dispatch } = useSalesPortalContext();
    const { payment, formSectionValid } = state;

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDateChange = (date: Date) => {
        dispatch({ type: 'setPayment', data: { startDate: date } });
    };

    const handlePaymentMethodChange = (value: SalesPortalState['payment']['method']) => {
        dispatch({
            type: 'setPayment',
            data: {
                method: value,
            },
        });
    };

    const handleTCsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: 'setPayment',
            data: {
                termsAndConditionsApproved: e.target.checked,
            },
        });
    };

    useEffect(() => {
        let isValid = false;
        if (payment.termsAndConditionsApproved === true) {
            isValid = true;
        }

        if (formSectionValid.payment !== isValid) {
            dispatch({
                type: 'setFormSectionValid',
                data: {
                    payment: isValid,
                },
            });
        }
    }, [payment, dispatch, formSectionValid]);

    return (
        <div className={styles.paymentInformationForm}>
            <form className={styles.formContainer}>
                <div>
                    <DatePicker
                        id="HomeCareCover_Checkout_Payment_ChangeStartDate"
                        testId="datepicker"
                        label="Cover plan start date"
                        onChange={handleDateChange}
                        value={payment.startDate}
                        minDate={new Date()}
                        maxDate={addMonths(new Date(), 3)}
                    />

                    <div className={styles.paymentMethodList} data-testid="payment-method-list">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label>Payment method</label>
                        <ul>
                            <li>
                                <Radio
                                    id="Payment_SelectTypeDD"
                                    onChange={() => handlePaymentMethodChange('directDebit')}
                                    checked={payment.method === 'directDebit'}
                                    name="directDebit"
                                >
                                    <div className={styles.eachPaymentMethod}>
                                        <span>Direct Debit</span>
                                        <div className={styles.directDebitLogo}>
                                            <DirectDebit />
                                        </div>
                                    </div>
                                </Radio>
                            </li>
                            <li>
                                <Radio
                                    id="Payment_SelectTypeCC"
                                    onChange={() => handlePaymentMethodChange('cardPayment')}
                                    checked={payment.method === 'cardPayment'}
                                    name="cardPayment"
                                    disabled
                                >
                                    <div className={styles.eachPaymentMethod}>
                                        <span>Card Payment</span>
                                        <div>
                                            <Visa />
                                            <MasterCard />
                                        </div>
                                    </div>
                                </Radio>
                            </li>
                        </ul>
                    </div>

                    <div className={styles.termsAndConditionsContainer}>
                        <div className={styles.termsContainer}>
                            <Checkbox
                                id="terms-checkbox"
                                checked={payment.termsAndConditionsApproved}
                                testId="terms-checkbox"
                                onChange={handleTCsChange}
                            >
                                <p>By proceeding with this purchase, I confirm that:</p>
                            </Checkbox>
                            <ul>
                                <li>
                                    I have confirmed the customers boiler is on the mains gas supply
                                    and in good working order
                                </li>
                                <li>I have read out the demands and need statement</li>
                                <li>I have read out the cancellation policy</li>
                                <li>I have completed a health check on the account </li>
                            </ul>
                        </div>

                        {isModalOpen && (
                            <Modal
                                isOpen={isModalOpen}
                                setIsOpen={setIsModalOpen}
                                style={{ backgroundColor: 'white', width: '90%' }}
                            >
                                <div className={styles.modalContent}>
                                    <h4>Good Working Order</h4>
                                    <p>
                                        We won’t be able to fix anything that is broken before you
                                        take out one of our care plans. Please make sure that all
                                        items you would like us to include are in full working order
                                        and have no pre-existing issues at the time of signing up.
                                        This includes any faults in your home, from broken boilers
                                        to leaky pipes and broken fuse boxes.
                                    </p>
                                </div>
                            </Modal>
                        )}
                    </div>
                </div>

                <PromoReferralCode />
            </form>
        </div>
    );
};
